import { useEstimatedVolume } from '~/pages/win168/_private/function/useEstimatedVolume';
import React from 'react';
import { getVolumeStatsDeviationRelativeStrongorWeak } from '~/pages/win168/_private/function/getVolumeRelativeStrongorWeak';
import { css } from '@emotion/react';
import { Thermometer } from '~/pages/win168/_private/Component/Thermometer';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useSnapshot } from 'valtio';
const applySeparator = (num, locale) => {
    return new Intl.NumberFormat(locale).format(num);
};
export function VolumeRelativeStrengthThermometer() {
    const tradedDate = useSnapshot(staticStore).tradedDate;
    // const day0 = tradedDate.day0
    const intraday = tradedDate.intraday;
    const lastIntraday = tradedDate.days?.[1].unix();
    const monthAgoIntraday = intraday.subtract(20, 'days').unix();
    const { data: estimatedVolumeData } = useEstimatedVolume({
        resoultion: 1,
        symbol: 'TXAM-1',
        refreshInterval: 10000,
    });
    // 取得歷史交易量
    const { data: historyVolumeData } = useSymbolHistoryResource({
        symbol: 'TXAM-1',
        resolution: '1D',
        fromTo: [monthAgoIntraday, lastIntraday],
        refreshInterval: 10000,
    });
    const volumeData = estimatedVolumeData?.v ?? [];
    // 如果第五個array的volume2非零，mergedVolumeData前五分個的volume2為第五個的volume2
    // 排除開盤後的第五分鐘波動較大的volume2
    // if (volumeData[4] !== 0) {
    //   const fifthVolume2 = volumeData[4]
    //   for (let i = 0; i < 5; i++) {
    //     volumeData[i] = fifthVolume2
    //   }
    // }
    if (!volumeData.length)
        return null;
    if (!historyVolumeData)
        return null;
    // const maxDaysVolume = Math.max(...volumeData)
    // const minDaysVolume = Math.min(...volumeData)
    let latestVolume = 0;
    for (let i = volumeData.length - 1; i >= 0; i--) {
        if (volumeData[i] !== 0) {
            latestVolume = volumeData[i];
            break; // 找到後中斷迴圈
        }
    }
    if (latestVolume === 0)
        return null;
    return (<div css={css `
        width: 100%;
        height: 110px;
        border: 1px solid #666666;
      `}>
      <Thermometer value={getVolumeStatsDeviationRelativeStrongorWeak(latestVolume, historyVolumeData)} maxValue={4}/>
    </div>);
}
