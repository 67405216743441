import { store } from '~/pages/heineken_template/_private/store';
export const win168_initStyling = () => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#00cc30',
        'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
        'mainSeriesProperties.candleStyle.downColor': '#00cc30',
        'mainSeriesProperties.candleStyle.upColor': '#cc0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        // 'paneProperties.legendProperties.showBarChange': true,
        'scalesProperties.showStudyLastValue': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#333333',
        'paneProperties.horzGridProperties.color': '#333333',
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#000000',
        'paneProperties.backgroundGradientEndColor': '#222222',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 16,
        'scalesProperties.textColor': '#cccccc',
        'scalesProperties.lineColor': '#cccccc',
    };
};
